import React, { useState, useEffect, useMemo } from "react";
import {
  TextField,
  Button,
  FormControl,
  FormLabel,
  Grid,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useIsMount } from "./UseIsMount";

// main component for zone select screen
function ZoneSelect() {
  // hook to navigate between pages
  const navigate = useNavigate();
  // hook to access redux store
  const dispatch = useDispatch();
  // hook to determine if component is being rendered for the first time
  const isMount = useIsMount();

  // keep track of current values in text fields
  const [venue, setVenue] = useState("");
  const [zone, setZone] = useState("");

  // colors for background cycle
  const colors = useMemo(
    () => ["#8f0101", "#8f3f01", "#8f8f01", "#048f01", "#01088f", "#77018f"],
    []
  );
  // keep track of which color is currently being displayed
  const [colorIndex, setColorIndex] = useState(0);

  // keep track of whether text fields are showing an error
  const error = useSelector((state) => state.connections.connectError);
  // keep track of connection status
  const connected = useSelector((state) => state.connections.connected);

  // if redirected here from the show page, make sure server connection is closed
  useEffect(() => {
    dispatch({ type: "RESET_CONNECTION" });
  }, []);

  // if connection is successful, redirect to show page.
  //  if this is being called on initial render, ignore it;
  //  otherwise it may trigger during page transition animation
  useEffect(() => {
    if (connected && !isMount) {
      return navigate("/show");
    }
  }, [connected]);

  // handle changes to text fields
  const handleVenueChange = (event) => {
    setVenue(event.target.value);
  };
  const handleZoneChange = (event) => {
    setZone(event.target.value);
  };

  // handle submission of form
  async function handleSubmit() {
    dispatch({ type: "TRY_CONNECT", payload: { venue: venue, zone: zone } });
  }

  // change background color every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(() => {
      setColorIndex((colorIndex + 1) % colors.length);
    }, 5000);
    return () => clearInterval(intervalId);
  }, [colorIndex, colors]);

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      display="flex"
      style={{
        height: "100%",
        background: colors[colorIndex],
        transition: "background 6s ease",
      }}
    >
      <Grid item>
        {/* form box */}
        <Box
          sx={{ p: 2, borderRadius: "16px" }}
          style={{ background: "white" }}
          display="flex"
          justifyContent="center"
        >
          <FormControl>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
            >
              <Grid item>
                {/* title */}
                <FormLabel style={{ fontSize: 26, color: "black" }}>
                  Welcome to MyLight.app!
                </FormLabel>
              </Grid>
              <Grid item>
                {/* subtitle (instructions) */}
                <FormLabel style={{ fontSize: 16, color: "black" }}>
                  Enter Your Venue and Zone IDs:
                </FormLabel>
              </Grid>
              <Grid item>
                {/* textfield for venue ID */}
                <TextField
                  label="Venue ID"
                  value={venue}
                  onChange={handleVenueChange}
                  error={error}
                />
              </Grid>
              <Grid item>
                {/* textfield for zone ID */}
                <TextField
                  label="Zone ID"
                  value={zone}
                  onChange={handleZoneChange}
                  error={error}
                  helperText={error ? "Invalid venue or zone!" : ""}
                />
              </Grid>
              <Grid item>
                {/* submit button */}
                <Button variant="contained" onClick={handleSubmit}>
                  Light It Up
                </Button>
              </Grid>
            </Grid>
          </FormControl>
        </Box>
      </Grid>
    </Grid>
  );
}

export default ZoneSelect;
