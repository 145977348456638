import { combineReducers } from "@reduxjs/toolkit";
const timesync = require("timesync");
const Timecode = require("smpte-timecode");

function framerateReducer(state = 29.97, action) {
  switch (action.type) {
    case "SET_FRAMERATE":
      return action.payload;
    case "RESET_CONNECTION":
      return 29.97;
    default:
      return state;
  }
}

function currentCueReducer(state = 0, action) {
  switch (action.type) {
    case "NEXT_CUE":
      return state + 1;
    case "PREV_CUE":
      return state - 1;
    case "RESET_CONNECTION":
      return 0;
    default:
      return state;
  }
}

function colorReducer(state = "black", action) {
  switch (action.type) {
    case "SET_COLOR":
      return action.payload;
    case "RESET_CLOCK":
      return action.payload.color;
    case "RESET_CONNECTION":
      return "black";
    default:
      return state;
  }
}

const timelineReducers = combineReducers({
  framerate: framerateReducer,
  currentCue: currentCueReducer,
  color: colorReducer,
});

export default timelineReducers;
