import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

function FromLink() {
  const { venue, zone } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const error = useSelector((state) => state.connections.connectError);
  const connected = useSelector((state) => state.connections.connected);

  useEffect(() => {
    dispatch({ type: "TRY_CONNECT", payload: { venue: venue, zone: zone } });
  }, []);

  useEffect(() => {
    if (error) {
      console.log("redirecting...");
      return navigate("/");
    }
  }, [error]);

  useEffect(() => {
    if (connected) {
      return navigate("/show");
    }
  }, [connected]);

  return <div />;
}

export default FromLink;
