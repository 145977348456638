import { combineReducers } from "@reduxjs/toolkit";
const timesync = require("timesync");

function connectedReducer(state = false, action) {
  switch (action.type) {
    case "CONNECTED":
      return true;
    case "DISCONNECTED":
    case "RESET_CONNECTION":
      return false;
    default:
      return state;
  }
}

// function tryConnectReducer(state = false, action) {
//   switch (action.type) {
//     case "TRY_CONNECT":
//       return true;
//     case "CONNECTED":
//     case "CONNECTION_ERROR":
//     case "RESET_CONNECTION":
//       return false;
//     default:
//       return state;
//   }
// }

function connectErrorReducer(state = false, action) {
  switch (action.type) {
    case "CONNECTION_ERROR":
      return true;
    case "TRY_CONNECT":
    case "CONNECTED":
    case "RESET_CONNECTION":
      return false;
    default:
      return state;
  }
}

function syncURLReducer(state = null, action) {
  switch (action.type) {
    case "CONNECTED":
      return "https://backend-" + action.payload.venue + ".mylight.app";
    case "RESET_CONNECTION":
      return null;
    default:
      return state;
  }
}
function assetsURLReducer(state = null, action) {
  switch (action.type) {
    case "CONNECTED":
      return (
        "https://storage.googleapis.com/assets.mylight.app/" +
        action.payload.venue +
        "/" +
        action.payload.zone
      );
    case "RESET_CONNECTION":
      return null;
    default:
      return state;
  }
}

const connectionReducers = combineReducers({
  connected: connectedReducer,
  // tryConnect: tryConnectReducer,
  connectError: connectErrorReducer,
  syncURL: syncURLReducer,
  assetsURL: assetsURLReducer,
});

export default connectionReducers;
