import { io } from "socket.io-client";
const Timecode = require("smpte-timecode");
const timesync = require("timesync");

const timeline = require("./Timeline");
import store from "./store";

export function initTS(serverURL) {
  console.log("initializing timesync with server: " + serverURL);
  // create a timesync instance
  var ts = timesync.create({
    server: serverURL + "/timesync",
    interval: 2000,
  });

  // get notified on changes in the offset
  ts.on("change", function (offset) {
    console.log("changed offset: " + offset + " ms<br>");
  });

  // return ts;
  store.dispatch({ type: "SET_TS", payload: ts });
}

// set up socket connection to the backend
export function initSync(serverURL) {
  var socket = io(serverURL);
  socket.on("connect", function (socket) {
    console.log("Connected to server!");
  });

  // handle incoming timecode packets
  socket.on("TIMECODE", (packet) => {
    handlePacket(packet);
  });

  // save socket to the state store
  store.dispatch({ type: "SET_SOCKET", payload: socket });
}

// initialize all server connections
export async function connect(venue, zone) {
  // try to load assets from storage server
  var result = await timeline.loadCueList(
    "https://storage.googleapis.com/assets.mylight.app/" + venue + "/" + zone
  );
  if (result === false) return false;

  // if assets load successfully, then venue and zone must be valid;
  //  initialize socket and timesync connections
  initSync("https://backend-" + venue + ".mylight.app");
  initTS("https://backend-" + venue + ".mylight.app");

  return true;
}

// handle incoming timecode packets
export function handlePacket(packet) {
  var timecode = new Timecode(packet.timecode);
  var ts = store.getState().nonserializable.ts;

  // if clock is running, then start the clock at the synced time
  if (packet.clock_running == true) {
    console.log("syncing clock start");
    var newGlobal = timecode.add(
      timeline.millisToTimecode(ts.now() - packet.synced_time)
    );
    console.log("global time sync: " + timecode.toString());
    store.dispatch({
      type: "START_CLOCK",
      payload: { fromTime: newGlobal, now: ts.now(), ignore: true },
    });
    // if clock is not running, then set the clock to the synced time
  } else {
    console.log("syncing clock stop");
    store.dispatch({ type: "PAUSE_CLOCK", payload: { ignore: true } });
    store.dispatch({ type: "SET_TIME", payload: timecode });

    store.dispatch({
      type: "SET_COLOR",
      payload: timeline.getCurrentFrame(timecode),
    });
  }
}

// send a timecode packet to the backend, for distribution to other clients
//  NOTE: this will not exist in the final version of the client
export function sendSnapshot() {
  var packet = {
    timecode: store.getState().clock.globalTime,
    clock_running: store.getState().clock.running,
    synced_time: store.getState().nonserializable.ts.now(),
  };
  store.getState().nonserializable.socket.emit("TIMECODE", "client", packet);
}
