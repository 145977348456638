import { configureStore, combineReducers } from "@reduxjs/toolkit";
import initSubscriber from "redux-subscriber";
import { persistStore } from "redux-persist";

import connectionReducers from "./connectionReducers.js";
import clockReducers from "./clockReducers.js";
import timelineReducers from "./timelineReducers.js";
import persistedReducer from "./persistedReducer.js";
const Timecode = require("smpte-timecode");
const servers = require("../Servers.js");
const timeline = require("../Timeline.js");

const myMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    switch (action.type) {
      case "TRY_CONNECT":
        var status = await servers.connect(
          action.payload.venue,
          action.payload.zone
        );

        if (status === true) {
          store.dispatch({
            type: "CONNECTED",
            payload: action.payload,
          });
        } else {
          store.dispatch({
            type: "CONNECTION_ERROR",
          });
        }
        return;

      case "START_CLOCK":
      case "PAUSE_CLOCK":
      case "RESET_CLOCK":
        if (
          action.payload === undefined ||
          action.payload.ignore === undefined ||
          action.payload.ignore === false
        ) {
          var ret = next(action);
          servers.sendSnapshot();
          return ret;
        }
        break;

      case "SET_TIME":
        dispatch({
          type: "SET_COLOR",
          payload: timeline.getCurrentFrame(action.payload),
        });
        break;
    }

    return next(action);
  };

export const store = configureStore({
  reducer: combineReducers({
    nonserializable: persistedReducer,
    connections: connectionReducers,
    clock: clockReducers,
    timeline: timelineReducers,
  }),

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false,
      serializableCheck: false,
    }).concat(myMiddleware),
});

initSubscriber(store);

export default store;
export const persistor = persistStore(store);
