import { motion } from "framer-motion";

export const PageTransition = (props) => {
    return (
      <motion.div
        {...props}
        initial={{ opacity: 0, y: "50vw" }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: "50vw" }}
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
        transition={{ type: "tween", duration: 0.3 }}
      >
        {props.children}
      </motion.div>
    );
  };