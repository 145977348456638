import React, { useEffect } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { AnimatePresence } from "framer-motion";
import { PageTransition } from "./Transitions";
import ZoneSelect from "./ZoneSelect";
import Show from "./Show";
import FromLink from "./FromLink";

// main app component
function MyApp() {
  // set base background color to black
  useEffect(() => {
    document.body.style.backgroundColor = "black";
    document.title = "MyLight.app";
    console.log("title set")
  }, []);

  // hook for current URL
  const location = useLocation();

  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route
          path="/"
          element={
            <PageTransition>
              {/* inital screen for selecting venue and zone */}
              <ZoneSelect />
            </PageTransition>
          }
        ></Route>
        <Route
          path="/show"
          element={
            <PageTransition>
              {/* screen that displays colors during a show */}
              <Show />
            </PageTransition>
          }
        ></Route>
        <Route
          path="/go/:venue/:zone"
          element={
            <PageTransition>
              <FromLink />
            </PageTransition>
          }
        ></Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
    </AnimatePresence>
  );
}

export default MyApp;
