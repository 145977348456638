const Timecode = require("smpte-timecode");
import ImageDecoder from "./ImageDecoder";
import store from "./store";

// load cuelist and assets from server and save to state store
export async function loadCueList(serverURL) {
  var assets = new Map();

  // get the cuelist file
  try {
    var response = await fetch(serverURL + "/cuelist.json");
    if (!response.ok) {
      console.log("Incorrect server address!");
      return false;
    }
    var packet = await response.json();
    var cuelist = packet.cues;

    // set the project framerate
    store.dispatch({ type: "SET_FRAMERATE", payload: packet.framerate });

    // load all assets
    for (const cue of cuelist) {
      if (cue.type == "media" && !assets.has(cue.asset)) {
        assets.set(
          cue.asset,
          new ImageDecoder(serverURL + "/media/" + cue.asset)
        );
      }
    }

    // save the cuelist and assets into the state store
    store.dispatch({ type: "SET_CUELIST", payload: cuelist });
    store.dispatch({ type: "SET_ASSETS", payload: assets });

    console.log("Cuelist loaded successfully!");
    return true;
  } catch (error) {
    console.log(error);
    return false;
  }
}

// convert milliseconds to timecode
export function millisToTimecode(
  millis,
  framerate = store.getState().timeline.framerate
) {
  return new Timecode((millis * framerate) / 1000, framerate);
}

// get the frame color corresponding to the current timecode
export function getCurrentFrame(currentTC) {
  var cuelist = store.getState().nonserializable.cuelist;
  var assets = store.getState().nonserializable.assets;
  var currentCue = () => store.getState().timeline.currentCue;
  if (!cuelist) {
    return "black";
  }

  // if current timecode is further, advance to the appropriate cue
  while (currentTC < Timecode(cuelist[currentCue()].timecode)) {
    store.dispatch({ type: "PREV_CUE" });
  }
  // if current timecode jumped backwards, go back in cuelist to find correct cue
  while (
    currentCue() + 1 < cuelist.length &&
    currentTC > Timecode(cuelist[currentCue() + 1].timecode)
  ) {
    store.dispatch({ type: "NEXT_CUE" });
  }

  var cue = cuelist[currentCue()];

  if (cue.type == "color") {
    // solid color cue; return specified color
    return cue.asset;
  } else {
    // media cue; load specified PNG file and calculate correct frame
    var asset = assets.get(cue.asset);
    return asset.getFrameColor(
      currentTC.frameCount -
        Timecode(cue.timecode).frameCount +
        cue.frame_offset
    );
  }
}
