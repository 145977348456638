class ImageDecoder {
    constructor(path) {
        var image = new Image();
        image.crossOrigin = 'Anonymous';
        image.onload = () => {
            var canvas = document.createElement('canvas')
            canvas.width = image.width;
            canvas.height = image.height;
            var context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);
            this.pixelData = context.getImageData(0, 0, image.width, image.height).data;
            this.totalFrames = image.width * image.height;
        }
        image.src = path;
    }

    getFrameColor(frame, offset=0) {
        if(this.pixelData && this.totalFrames && frame < this.totalFrames) {
            var index = (frame - offset) * 4;

            let red = this.pixelData[index];
            let green = this.pixelData[index + 1];
            let blue = this.pixelData[index + 2];

            return 'rgb(' + red + ', ' + green + ', ' +  blue + ')';
        }
        else {
            return 'black';
        }
    }
}

export default ImageDecoder;