import React, { useState, useEffect } from "react";
import { Grid, Typography } from "@mui/material";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import NoSleep from "nosleep.js";
import IconButton from "@mui/material/IconButton";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import useInterval from "./UseInterval";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch, useStore } from "react-redux";
const timeline = require("./Timeline.js");

const Timecode = require("smpte-timecode");

function Show() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const store = useStore();

  // if not connected, redirect to home page
  useEffect(() => {
    if (store.getState().connections.connected == false) {
      console.log("redirecting...");
      return navigate("/");
    }
  }, []);

  // state access hooks
  const ts = useSelector((state) => state.nonserializable.ts);
  const socket = useSelector((state) => state.nonserializable.socket);
  const color = useSelector((state) => state.timeline.color);
  const globalTime = useSelector((state) => state.clock.globalTime);
  const clockRunning = useSelector((state) => state.clock.running);
  const startTimeMillis = useSelector((state) => state.clock.startTimeMillis);

  // header opacity state
  const [headerColor, setHeaderColor] = useState("rgba(0, 0, 0, 0.5)");
  // menu visibility state
  const [anchorEl, setAnchorEl] = useState(null);
  // debug mode visibility state
  const [debug, setDebug] = useState(false);

  // fullscreen handler
  const handle = useFullScreenHandle();
  // wakelock handler
  var noSleep = new NoSleep();

  // update timecode position every 20ms while clock is running
  useInterval(
    () => {
      // get current timecode
      var currentTime = timeline.millisToTimecode(ts.now() - startTimeMillis);
      // if time has changed since last update, then update the clock
      if (currentTime > globalTime)
        dispatch({ type: "SET_TIME", payload: currentTime });
    },
    clockRunning ? 20 : null
  );

  // handle when the user clicks the menu button
  const handleMenuClick = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  // handle when the menu is closed
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  // handle fullscreen state changes
  function reportChange(state) {
    // entering fullscreen
    if (state === true) {
      // enable wakelock
      noSleep.enable();
      // hide header
      setHeaderColor("rgba(0, 0, 0, 0)");
      // exiting fullscreen
    } else if (state === false) {
      // disable wakelock
      noSleep.disable();
      // show header
      setHeaderColor("rgba(0, 0, 0, 0.5)");
    }
  }

  // toggle fullscreen mode
  function toggleFS() {
    // if in fullscreen, exit fullscreen
    if (handle.active) {
      handle.exit();
      // if not in fullscreen, enter fullscreen (unless menu is showing)
    } else if (anchorEl === null) {
      handle.enter();
    }
  }

  // toggle debug mode
  function toggleDebug() {
    setDebug((prevDebug) => !prevDebug);
    handleMenuClose();
  }

  return (
    <FullScreen handle={handle} onChange={reportChange}>
      <Grid
        container
        direction="column"
        justifyContent={"top"}
        style={{ minWidth: "100vw", height: "100vh", background: color }}
        onClick={toggleFS}
      >
        {/* header bar */}
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          style={{ minWidth: "100vw", background: headerColor }}
        >
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-start",
              minWidth: "10vw",
            }}
          ></Grid>
          <Grid item style={{ display: "flex", justifyContent: "center" }}>
            <Typography color="white" style={{ fontSize: 14, padding: 16 }}>
              Tap to {handle.active ? "exit" : "enter"} fullscreen mode
            </Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              justifyContent: "flex-end",
              minWidth: "10vw",
            }}
          >
            <IconButton
              aria-label="menu"
              onClick={handleMenuClick}
              style={{
                color: "white",
                display: handle.active ? "none" : "block",
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="simple-menu"
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={toggleDebug} onClose={handleMenuClose}>
                {debug ? "Disable" : "Enable"} Debug Mode
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
        {/* debug view */}
        <Grid
          container
          textAlign={"center"}
          style={{ display: debug ? "block" : "none" }}
        >
          <Typography color="white" style={{ fontSize: 24, padding: 16 }}>
            {globalTime.toString()}
          </Typography>
          {/* <Button
            variant="contained"
            style={{ margin: 10 }}
            onClick={(e) => {
              e.stopPropagation();

              if (clockRunning) {
                dispatch({ type: "PAUSE_CLOCK" });
              } else {
                dispatch({
                  type: "START_CLOCK",
                  payload: { fromTime: globalTime, now: ts.now() },
                });
              }
            }}
          >
            Toggle Clock
          </Button>
          <Button
            variant="contained"
            style={{ margin: 10 }}
            onClick={(e) => {
              e.stopPropagation();
              dispatch({
                type: "RESET_CLOCK",
                payload: {
                  color: timeline.getCurrentFrame(
                    Timecode(0, store.getState().timeline.framerate)
                  ),
                },
              });
            }}
          >
            Reset Clock
          </Button> */}
        </Grid>
      </Grid>
    </FullScreen>
  );
}

export default Show;
