import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

const initialState = {
  ts: null,
  socket: null,
  cuelist: null,
  assets: null,
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_TS":
      return {
        ...state,
        ts: action.payload,
      };
    case "SET_SOCKET":
      return {
        ...state,
        socket: action.payload,
      };
    case "SET_CUELIST":
      return {
        ...state,
        cuelist: action.payload,
      };
    case "SET_ASSETS":
      return {
        ...state,
        assets: action.payload,
      };
    case "RESET_CONNECTION":
      if (state.ts !== null) state.ts.destroy();
      if (state.socket !== null) state.socket.close();
      return {
        ...state,
        ts: null,
        socket: null,
        cuelist: null,
        assets: null,
      };
    default:
      return state;
  }
};

const persistConfig = {
  key: "root",
  storage,
  blacklist: ["ts", "socket", "cuelist", "assets"], // Add any non-serializable data to the blacklist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export default persistedReducer;
