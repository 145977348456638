import { combineReducers } from "@reduxjs/toolkit";
const timesync = require("timesync");
const Timecode = require("smpte-timecode");
const timeline = require("../Timeline");

function runningReducer(state = false, action) {
  switch (action.type) {
    case "START_CLOCK":
      return true;
    case "PAUSE_CLOCK":
    case "RESET_CLOCK":
      return false;
    default:
      return state;
  }
}

function globalTimeReducer(state = new Timecode(0), action) {
  switch (action.type) {
    case "SET_TIME":
      return action.payload;
    case "START_CLOCK":
      return action.payload.fromTime;
    case "RESET_CONNECTION":
    case "RESET_CLOCK":
      return new Timecode(0);
    default:
      return state;
  }
}

function startTimeMillisReducer(state = 0, action) {
  switch (action.type) {
    case "START_CLOCK":
      var fromTime = action.payload.fromTime;
      var globalMillis = (1000.0 * fromTime.frameCount) / fromTime.frameRate;
      return action.payload.now - globalMillis;
    case "RESET_CONNECTION":
    case "RESET_CLOCK":
      return 0;
    default:
      return state;
  }
}

const clockReducers = combineReducers({
  running: runningReducer,
  globalTime: globalTimeReducer,
  startTimeMillis: startTimeMillisReducer,
});

export default clockReducers;
